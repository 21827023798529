<template>
  <b-form-checkbox
    v-model="inputValue"
    v-bind="$attrs"
    name="check-button"
    switch
    v-on="$listeners"
  >
    Preview
  </b-form-checkbox>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('update:value', val)
      }
    }
  }
}
</script>
